// eslint-disable-next-line import/prefer-default-export
export default function fulfillmentMenuItems(active, params = {}) {
  const menuItems = [
    {
      name: 'settings-fulfillment-warehouses',
      layout: 'Warehouses',
    },
    {
      name: 'settings-fulfillment-operation-hours',
      layout: 'Hours',
    },
    // {
    //   name: 'settings-fulfillment-processes',
    //   layout: 'Processes',
    // },
    {
      name: 'settings-fulfillment-rules',
      layout: 'Rules',
    },
    {
      name: 'settings-fulfillment-templates',
      layout: 'Templates',
    },
    {
      name: 'settings-fulfillment-dispatch',
      layout: 'Dispatch',
    },
    {
      name: 'settings-fulfillment-labels',
      layout: 'Labels',
    },
    // {
    //  name: 'settings-fulfillment-couriers',
    //  layout: 'Couriers',
    // },
  ]
  menuItems.forEach(item => {
    // eslint-disable-next-line no-param-reassign
    item.active = item.name === active
    // eslint-disable-next-line no-param-reassign
    item.route = { name: item.name, params }
  })
  return {
    menuItems,
  }
}
