<template>
  <sub-menu-component :menu-items="menuItems">
    <router-view @updateMenu="updateMenu" />
  </sub-menu-component>
</template>

<script>
import fulfillmentMenuItems from '@/views/components/sub-menu/settings-sub-menu/fulfillment'
import { ref } from '@vue/composition-api'

export default {
  name: 'Fulfillment',
  methods: {
    updateMenu(active) {
      this.menuItems = fulfillmentMenuItems(active).menuItems
    },
  },
  setup() {
    const menuItems = ref()
    return {
      menuItems,
    }
  },
}
</script>

<style scoped>

</style>
